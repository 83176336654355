@tailwind base;
@tailwind components;
@tailwind utilities;
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: 'JostBold';
  src: local('JostBold'), url('../fonts/jost-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Jost';
  src: local('Jost'), url('../fonts/jost-regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'JostMedium';
  src: local('JostMedium'), url('../fonts/jost-medium.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'JostExtralight';
  src: local('JostExtralight'), url('../fonts/jost-extralight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'JostLight';
  src: local('JostLight'), url('../fonts/jost-light.ttf') format('truetype');
  font-weight: 200;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}
