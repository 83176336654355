.terms-of-service {
  background-color: #e9edfa;
  height: auto;

  .banner {
    background-color: #0f152a;
    padding: 3rem;
    font-size: 2rem;
    color: white;
    text-align: center;
    // font-family: 'Raleway-SemiBold';

    @media (max-width: 800px) {
      padding: 1.8rem;
      font-size: 1.2rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      padding: 2rem;
      font-size: 1.5rem;
    }
  }

  .terms-content {
    padding: 2.5rem 15rem;
    padding-bottom: 10rem;

    @media (max-width: 800px) {
      padding: 2rem 3rem;
      padding-bottom: 10rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      padding: 2.5rem 5rem;
      padding-bottom: 10rem;
    }

    .space {
      margin-top: 2rem;
    }

    .t-title {
      font-family: 'JostBold';
      font-size: 1.3rem;

      @media (max-width: 800px) {
        font-size: 1rem;
      }
    }

    .bold {
      font-family: 'JostBold';
    }

    .content {
      // font-family: 'Raleway-Medium';
      font-size: 1rem;

      // @media (max-width: 800px) {
      //   font-size: 0.8rem;
      // }

      .link {
        color: black;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 0.3rem;
      }
    }
  }
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
  font-weight: bold;
}

ol > li ol > li {
  margin: 0;
}

ol > li ol > li:before {
  content: counters(item, ".") " ";
}

ul {
  list-style-type: upper-alpha;
}

ul > li:before {
  display: table-cell;
  font-weight: bold;
}