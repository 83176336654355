.privacy-policy {
  background-color: #e9edfa;
  height: auto;

  .banner {
    background-color: #0f152a;
    padding: 3rem;
    font-size: 2rem;
    color: white;
    text-align: center;
    // font-family: 'Raleway-SemiBold';

    @media (max-width: 800px) {
      padding: 1.8rem;
      font-size: 1.2rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      padding: 2rem;
      font-size: 1.5rem;
    }
  }

  .privacy-content {
    padding: 2.5rem 15rem;
    padding-bottom: 10rem;
    // font-family: 'Raleway-Medium';

    @media (max-width: 800px) {
      padding: 2rem 3rem;
      padding-bottom: 10rem;
    }

    @media (min-width: 800px) and (max-width: 1024px) {
      padding: 2.5rem 5rem;
      padding-bottom: 10rem;
    }

    .space {
      margin-top: 2rem;
    }

    .link {
      color: black;
      text-decoration: underline;
      cursor: pointer;
      margin-left: 0.3rem;
      margin-right: 0.3rem;
    }
  }
}